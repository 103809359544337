import { userConstants } from "../_constants";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  const { user } = state;
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    case userConstants.CHANGE_LOGIN_DATA:
      return { ...state, user: { ...user, ...action.user } };
    case userConstants.UPDATE_LOGIN_DATA_REQUEST: {
      return state;
    }
    case userConstants.UPDATE_LOGIN_DATA: {
      // console.log("UPDATE_LOGIN_DATA", user);
      return { ...state, user: { ...user, ...action.user } };
    }
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return { ...state, sendingForgetPassEmail: true };
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return { ...state, sendingForgetPassEmail: false };
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return { ...state, sendingForgetPassEmail: false };
    case userConstants.RESET_PASSWORD_REQUEST:
      return { ...state, resetingPassword: true };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return { ...state, resetingPassword: false };
    case userConstants.RESET_PASSWORD_FAILURE:
      return { ...state, resetingPassword: false };
    default:
      return state;
  }
}
