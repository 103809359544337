import React from "react";
import { Route, Switch, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { userActions, alertActions, heatpumpActions } from "../_actions";
import { PrivateRoute, Loader, Page404 } from "../_components";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { HomePage } from "../HomePage";
import { LoginPage } from "../LoginPage";
import { Role } from "../_helpers";
import {
  UsersPage,
  RegisterPage,
  UpdatePage,
  ProfilePage,
  ChangePassPage
} from "../UsersManagementPages";
import {
  HeatpumpsPage,
  RegisterHeatpumpPage,
  UpdateHeatpumpPage
} from "../HeatpumpsManagementPages";
import { MainScreenPage } from "../MainScreenPage";
import { ForgotPasswordPage, PasswordResetPage } from "../PasswordMngPages";
import { fetchService } from "../_services";

class App extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch, history } = this.props;

    history.listen(() => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  componentDidMount() {
    const { authentication, dispatch, location } = this.props;
    const localUser = JSON.parse(localStorage.getItem("user"));

    // console.log("getDataAfterRefresh");

    if (localUser && authentication.user) {
      // console.log("getDataAfterRefresh");
      dispatch(
        userActions.getDataAfterRefresh(
          localUser._id,
          dispatch,
          location.pathname
        )
      );
      /* if (location.pathname.startsWith("/update/"))
        dispatch(userActions.getUpdateDataAfterRefresh(dispatch, location.pathname)); */
    }
  }

  logout = () => {
    const { dispatch } = this.props;
    dispatch(userActions.clearUsers());
    dispatch(userActions.clearUpdateUserData());
    dispatch(heatpumpActions.clearHeatpumps());
    dispatch(heatpumpActions.clearUpdateHeatpumpData());
    fetchService.logout();
    // history.push("/");
  };

  render() {
    const { alert, authentication, location } = this.props;
    const { user } = authentication;
    const loggedIn = JSON.parse(localStorage.getItem("user"));
    let loaderOrLogin = "";
    if (loggedIn) {
      loaderOrLogin = <Route component={Loader} />;
    } else {
      loaderOrLogin = <Route component={LoginPage} />;
    }

    const mainScreenShowing = location.pathname === "/mainscreen";

    return (
      <div>
        {user && user.role && <Header logout={this.logout} />}
        <div
          className={
            mainScreenShowing
              ? "jumbotron main-screen-class-jumbotron"
              : "jumbotron"
          }
        >
          <div className="container-fluid">
            {alert.message && (
              <div className="row">
                <div className="col col-sm-6 offset-xl-3">
                  <div className={`alert ${alert.type}`}>{alert.message}</div>
                  {alert.forgotPassword && (
                    <Link to="/forget-password">Forgot your password?</Link>
                  )}
                </div>
              </div>
            )}
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route path="/forget-password" component={ForgotPasswordPage} />
              <Route
                path="/reset-password/:id/:token"
                component={PasswordResetPage}
              />
              {user && user.role ? (
                <Switch>
                  <PrivateRoute exact path="/" component={HomePage} />
                  <PrivateRoute path="/mainscreen" component={MainScreenPage} />
                  <PrivateRoute path="/profile" component={ProfilePage} />
                  <PrivateRoute path="/changepass" component={ChangePassPage} />
                  <PrivateRoute path="/heatpump" component={HeatpumpsPage} />

                  <PrivateRoute
                    path="/update-heatpump/:id"
                    component={UpdateHeatpumpPage}
                  />
                  {user.role === Role.Manufacturer ||
                  user.role === Role.Partner ? (
                    <Switch>
                      <PrivateRoute path="/users" component={UsersPage} />
                      <PrivateRoute path="/register" component={RegisterPage} />
                      <PrivateRoute
                        path="/register-heatpump"
                        component={RegisterHeatpumpPage}
                      />
                      <PrivateRoute path="/update/:id" component={UpdatePage} />
                      <Route component={Page404} />
                    </Switch>
                  ) : (
                    <Switch>
                      <Route component={Page404} />
                    </Switch>
                  )}
                </Switch>
              ) : (
                loaderOrLogin
              )}
            </Switch>
          </div>
        </div>
        <div className={mainScreenShowing ? "main-screen-class-footer" : ""}>
          <Footer loggedOut={!loggedIn} />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  authentication: PropTypes.shape({}).isRequired,
  alert: PropTypes.shape({}).isRequired,
  updatingUserData: PropTypes.shape({}).isRequired
};

function mapStateToProps(state) {
  const { alert, authentication, updatingUserData } = state;
  return {
    alert,
    authentication,
    updatingUserData
  };
}

const connectedApp = withRouter(connect(mapStateToProps)(App));
export { connectedApp as App };
