/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { userConstants } from "../_constants";
import { fetchService } from "../_services";
import { alertActions, changeUpdatingUserData, heatpumpActions } from ".";
import { Role } from "../_helpers";
// import { history } from '../_helpers';

function login(username, password, history) {
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
  return dispatch => {
    dispatch(request({ username }));

    fetchService.login(username, password).then(
      user => {
        dispatch(success(user));
        history.push("/");
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString(), true)); // true is for Forgot password link to show up
      }
    );
  };
}

function changePassword(username, oldPassword, newPassword, history, prevPath) {
  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS };
  }
  function failure() {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE };
  }
  return dispatch => {
    dispatch(request());

    fetchService.login(username, oldPassword, true).then(
      loggedInUser => {
        fetchService
          .update({ id: loggedInUser._id, password: newPassword }, "users")
          .then(
            user => {
              dispatch(success());
              history.push(prevPath);
              dispatch(alertActions.success("Password change successful"));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(error.toString()));
            }
          );
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function logout() {
  fetchService.logout();
  return { type: userConstants.LOGOUT };
}

function clearUsers() {
  return { type: userConstants.CLEAR_USERS };
}

function clearUpdateUserData() {
  fetchService.clearUpdateUserData();
  return { type: userConstants.CLEAR_UPDATING_USER_DATA };
}

function register(user, history) {
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
  return dispatch => {
    dispatch(request(user));

    fetchService.register(user, "users").then(
      user => {
        dispatch(success(user));
        history.push("/users");
        dispatch(alertActions.success("Registration successful"));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

// added on 12.03.2019
function update(user, history, prevPath) {
  function request() {
    return { type: userConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: userConstants.UPDATE_SUCCESS };
  }
  function failure() {
    return { type: userConstants.UPDATE_FAILURE };
  }
  return dispatch => {
    dispatch(request());

    fetchService.update(user, "users").then(
      () => {
        dispatch(success());
        if (prevPath && history) {
          history.push(prevPath);
          dispatch(alertActions.success("Update successful"));
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function getAll() {
  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
  return dispatch => {
    dispatch(request());

    fetchService
      .getAll("users")
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function getByOwner(owner) {
  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
  return dispatch => {
    dispatch(request());

    fetchService
      .getByOwner(owner, "users")
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function getDataAfterRefresh(id, dispatch, currentPath) {
  function request() {
    return { type: userConstants.UPDATE_LOGIN_DATA_REQUEST };
  }

  function success(user) {
    if (currentPath === "/users") {
      if (user.role === Role.Manufacturer) {
        dispatch(getAll());
      } else if (user.role === Role.Partner) {
        dispatch(getByOwner(user.username));
      } else if (currentPath === "/heatpump") {
        if (user.role === Role.Manufacturer) {
          dispatch(heatpumpActions.getAll());
        } else if (user.role === Role.Partner) {
          dispatch(heatpumpActions.getByOwner(user.username));
        }
      }
    }

    return { type: userConstants.UPDATE_LOGIN_DATA, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());
    fetchService
      .getById(id, "users")
      .then(
        user => dispatch(success(user)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function getUpdateDataAfterRefresh(dispatch, currentPath) {
  function request() {
    return { type: userConstants.UPDATE_REQUEST };
  }

  function success(user) {
    dispatch(
      changeUpdatingUserData({
        id: user._id,
        gender: user.gender,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        company: user.company,
        street: user.street,
        zipcode: user.zipcode,
        city: user.city,
        country: user.country,
        language: user.language,
        phone: user.phone,
        mobilePhone: user.mobilePhone,
        username: user.username,
        role: user.role,
        owner: user.owner,
        prevPath: "/users"
      })
    );

    return { type: userConstants.UPDATE_SUCCESS };
  }

  function failure() {
    return { type: userConstants.UPDATE_FAILURE };
  }

  return dispatch => {
    dispatch(request());
    fetchService
      .getById(currentPath.replace("/update/", ""), "users")
      .then(user => dispatch(success(user)), () => dispatch(failure()));
  };
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, history, prevPath) {
  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
  return dispatch => {
    dispatch(request(id));

    fetchService.delete(id, "users").then(
      () => {
        dispatch(success(id));
        history.push(prevPath);
        dispatch(alertActions.success("Delete successful"));
      },
      error => {
        dispatch(failure(id, error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function forgotPassword(email, history) {
  function request(email) {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST };
  }
  function success(respond) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE };
  }
  return dispatch => {
    dispatch(request({ email }));

    fetchService.forgotPassword(email).then(
      respond => {
        dispatch(success(respond));
        history.push("/login");
        dispatch(
          alertActions.success("Check your mail to reset your password.")
        );
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function resetPassword(id, token, password, history) {
  function request() {
    return { type: userConstants.RESET_PASSWORD_REQUEST };
  }
  function success(respond) {
    return { type: userConstants.RESET_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.RESET_PASSWORD_FAILURE };
  }
  return dispatch => {
    dispatch(request());

    fetchService.resetPasswords(id, token, password).then(
      respond => {
        dispatch(success(respond));
        history.push("/login");
        dispatch(alertActions.success("Password updated successfully."));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

export const userActions = {
  login,
  logout,
  register,
  getAll,
  delete: _delete,
  update,
  clearUsers,
  clearUpdateUserData,
  changePassword,
  getByOwner,
  getDataAfterRefresh,
  getUpdateDataAfterRefresh,
  forgotPassword,
  resetPassword
};
