import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import {
  heatpumpActions,
  changeUpdatingHeatpumpData,
  userActions
} from "../_actions";
import { Role, validate } from "../_helpers";
import { countryList } from "../_constants";
import {
  MyInputText,
  MySelect,
  MyInputOwner,
  MySubmitCancel,
  MyBootstrapModal,
  MyInputIp
} from "../_forms";
import { updateHeatpumpsList } from ".";
import { Page404 } from "../_components";

class UpdateHeatpumpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      ownerExists: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      updatingHeatpumpData,
      dispatch,
      location,
      match,
      currentUser,
      users
    } = this.props;
    if (
      !updatingHeatpumpData.id ||
      match.params.id !== updatingHeatpumpData.id
    ) {
      dispatch(
        heatpumpActions.getUpdateDataAfterRefresh(dispatch, location.pathname)
      );
    }
    if (users.length === 0) {
      if (currentUser.role === Role.Manufacturer) {
        dispatch(userActions.getAll());
      }
      updateHeatpumpsList(dispatch, currentUser);
    }
  }

  handleDeleteHeatpump() {
    const { dispatch, history, updatingHeatpumpData } = this.props;
    dispatch(
      heatpumpActions.delete(
        updatingHeatpumpData.id,
        history,
        updatingHeatpumpData.prevPath
      )
    );
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { dispatch, updatingHeatpumpData } = this.props;

    this.setState({
      ownerExists: true
    });

    dispatch(
      changeUpdatingHeatpumpData({
        ...updatingHeatpumpData,
        [name]: value
      })
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    const { dispatch, history, updatingHeatpumpData } = this.props;
    const ownerExists = this.ownerExists();

    this.setState({ submitted: true, ownerExists });

    if (
      updatingHeatpumpData.serial &&
      updatingHeatpumpData.name &&
      updatingHeatpumpData.street &&
      updatingHeatpumpData.zipcode &&
      updatingHeatpumpData.city &&
      updatingHeatpumpData.country &&
      updatingHeatpumpData.model &&
      validate.ipAddres(updatingHeatpumpData.ipAddress) &&
      ownerExists
    ) {
      dispatch(
        heatpumpActions.update(
          updatingHeatpumpData,
          history,
          updatingHeatpumpData.prevPath
        )
      );
    }
  }

  ownerExists() {
    const { users, currentUser, updatingHeatpumpData } = this.props;

    if (
      users.filter(
        userInItems => userInItems.username === updatingHeatpumpData.owner
      ).length > 0 ||
      currentUser.username === updatingHeatpumpData.owner
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      updating,
      history,
      currentUser,
      updatingHeatpumpData,
      match,
      heatpumps
    } = this.props;
    const { submitted, ownerExists } = this.state;
    const modalId = "confirmModal";

    if (
      heatpumps &&
      updatingHeatpumpData &&
      updatingHeatpumpData.id &&
      match.params.id === updatingHeatpumpData.id
    ) {
      const myHeatpump =
        heatpumps.filter(heatpump => heatpump._id === updatingHeatpumpData.id)
          .length > 0;

      if (myHeatpump) {
        return (
          <div className="col-sm-12">
            <h2 className="offset-xl-3">
              Update heatpump {updatingHeatpumpData.name}:
            </h2>
            <form name="form" onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div
                  className="col-sm-3 offset-xl-3"
                  style={{ minWidth: "240px" }}
                >
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingHeatpumpData.serial}
                    inputName="serial"
                    eventHandler={this.handleChange}
                    inputReadOnly={currentUser.role === Role.User}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingHeatpumpData.name}
                    inputName="name"
                    eventHandler={this.handleChange}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingHeatpumpData.model}
                    inputName="model"
                    eventHandler={this.handleChange}
                    inputReadOnly={currentUser.role === Role.User}
                  />
                  {currentUser.role !== Role.User && (
                    <MyInputIp
                      submitted={submitted}
                      inputValue={updatingHeatpumpData.ipAddress}
                      inputName="ipAddress"
                      eventHandler={this.handleChange}
                    />
                  )}
                  {currentUser.role === Role.Manufacturer && (
                    <MyInputOwner
                      submitted={submitted}
                      ownerExists={ownerExists}
                      inputValue={updatingHeatpumpData.owner}
                      inputName="owner"
                      eventHandler={this.handleChange}
                    />
                  )}
                </div>
                <div className="col-sm-3" style={{ minWidth: "240px" }}>
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingHeatpumpData.street}
                    inputName="street"
                    eventHandler={this.handleChange}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingHeatpumpData.zipcode}
                    inputName="zipcode"
                    eventHandler={this.handleChange}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingHeatpumpData.city}
                    inputName="city"
                    eventHandler={this.handleChange}
                  />
                  <MySelect
                    value={updatingHeatpumpData.country}
                    name="country"
                    eventHandler={this.handleChange}
                    optionsArray={countryList}
                  />
                </div>
                {currentUser.role !== Role.User && (
                  <div className="col-lg-3 pt-4 pb-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target={`#${modalId}`}
                    >
                      Delete Heatpump
                    </button>
                    <MyBootstrapModal
                      modalId={modalId}
                      item="heatpump"
                      eventHandler={() => this.handleDeleteHeatpump()}
                    />
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="col-sm-3 offset-xl-3">
                  <MySubmitCancel
                    ongoingAction={updating}
                    submitButtonLabel="Update"
                    cancelExists
                    eventHandler={() => history.goBack()}
                  />
                </div>
              </div>
            </form>
          </div>
        );
      }
      return <Page404 />;
    }
    return "";
  }
}

UpdateHeatpumpPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  updating: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object),
  heatpumps: PropTypes.arrayOf(PropTypes.object),
  updatingHeatpumpData: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired
};

UpdateHeatpumpPage.defaultProps = {
  updating: false,
  users: [],
  heatpumps: []
};

function mapStateToProps(state) {
  const { updating } = state.updating;
  const { updatingHeatpumpData, authentication } = state;
  const users = state.users.items;
  const heatpumps = state.heatpumps.items;
  const currentUser = authentication.user;
  return {
    updating,
    updatingHeatpumpData,
    users,
    heatpumps,
    currentUser
  };
}

const connectedUpdateHeatpumpPage = withRouter(
  connect(mapStateToProps)(UpdateHeatpumpPage)
);
export { connectedUpdateHeatpumpPage as UpdateHeatpumpPage };
