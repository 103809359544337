import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  submitted: PropTypes.bool.isRequired,
  inputValue: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  eventHandler: PropTypes.func.isRequired
};

export function MyInputEmail({
  submitted,
  inputValue,
  inputName,
  eventHandler
}) {
  const inputNameForUser = inputName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, str => str.toUpperCase());
  return (
    <div
      className={`form-group${submitted && !inputValue ? " text-danger" : ""}`}
    >
      <label htmlFor={inputName}>
        {inputNameForUser}
        <input
          type="email"
          className="form-control"
          name={inputName}
          value={inputValue}
          onChange={eventHandler}
          placeholder={`Enter your ${inputNameForUser}`}
        />
      </label>
      {submitted && !inputValue && (
        <div className="help-block">{inputNameForUser} is required</div>
      )}
    </div>
  );
}

MyInputEmail.propTypes = propTypes;
MyInputEmail.defaultProps = {
  inputValue: ""
};
