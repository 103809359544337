import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getLightColor, getUnitStatusText, Role } from "../_helpers";
import { changeUpdatingUserData, heatpumpActions } from "../_actions";

class Header extends React.Component {
  state = { mainScreenPageEnabled: true };

  componentDidMount() {
    const {
      authentication: { user },
      dispatch
    } = this.props;
    // check if pump is active
    if (user.activeHeatpump) {
      dispatch(heatpumpActions.getByIdAndCheckConnection(user.activeHeatpump));
    }
    // console.log("Header mounted");
  }

  handleUpdateProfileData = () => {
    const {
      authentication: { user },
      dispatch
    } = this.props;
    dispatch(
      changeUpdatingUserData({
        id: user._id,
        gender: user.gender,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        company: user.company,
        street: user.street,
        zipcode: user.zipcode,
        city: user.city,
        country: user.country,
        language: user.language,
        phone: user.phone,
        mobilePhone: user.mobilePhone,
        username: user.username,
        password: "",
        newPassword: "",
        prevPath: "/"
      })
    );
  };

  handleChangeUserPassword = () => {
    const {
      authentication: { user },
      dispatch
    } = this.props;
    dispatch(
      changeUpdatingUserData({
        id: user._id,
        gender: user.gender,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        company: user.company,
        street: user.street,
        zipcode: user.zipcode,
        city: user.city,
        country: user.country,
        language: user.language,
        phone: user.phone,
        mobilePhone: user.mobilePhone,
        username: user.username,
        password: "",
        newPassword: "",
        prevPath: "/"
      })
    );
  };

  /* heatpumpsOnClickHandler = () => {
    this.setState({ mainScreenPageEnabled: false });
    setTimeout(() => this.setState({ mainScreenPageEnabled: true }), 500); // disabled for 0.5 secs bacause server api hangs if multiple fetching
  }; */

  render() {
    const {
      authentication: { user, loggedIn },
      activeHeatpumpStatus,
      logout,
      location,
      activeHeatpumpData,
    } = this.props;
    const { mainScreenPageEnabled } = this.state;
    const mainScreenShowing = location.pathname === "/mainscreen";
    const mainScreenClass = "main-screen-class";
    const notMainScreenClass = "not-main-screen-class";

    return (
      <div>
        {loggedIn && (
          <nav
            id="nav-ribbon"
            className={
              mainScreenShowing
                ? `${mainScreenClass} navbar navbar-expand-sm navbar-dark bg-primary`
                : `${notMainScreenClass} navbar navbar-expand-sm navbar-dark bg-primary`
            }
          >
            <h3
              id="naslov-mobile"
              className={
                mainScreenShowing
                  ? `${mainScreenClass} navbar-nav mx-auto`
                  : `${notMainScreenClass} navbar-nav mx-auto`
              }
            >
              GTR Heatpumps
            </h3>
            {mainScreenShowing && <h3 id='naslov-hp-model' className='hide-naslov-hp-model'>{activeHeatpumpData.name}</h3>}
            <button
              className={
                mainScreenShowing
                  ? `${mainScreenClass} navbar-toggler ml-auto`
                  : `${notMainScreenClass} navbar-toggler ml-auto`
              }
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={
                mainScreenShowing
                  ? `${mainScreenClass} collapse navbar-collapse`
                  : `${notMainScreenClass} collapse navbar-collapse`
              }
              id="navbarCollapse"
            >
              <div
                className="navbar-nav mr-auto"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/" className="nav-item nav-link">
                  Home
                </Link>
                {user.role !== Role.User && (
                  <Link to="/users" className="nav-item nav-link">
                    Users
                  </Link>
                )}
                <Link
                  // onClick={this.heatpumpsOnClickHandler}
                  to="/heatpump"
                  className="nav-item nav-link"
                >
                  Heatpump{user.role !== Role.User && "s"}
                </Link>
                <span
                  className={
                    mainScreenShowing
                      ? `${mainScreenClass} online-container`
                      : `${notMainScreenClass} online-container`
                  }
                >
                  <Link
                    to="/mainscreen"
                    className={
                      mainScreenPageEnabled
                        ? "nav-item nav-link"
                        : "nav-item nav-link disabled"
                    }
                  >
                    Main Screen &nbsp;
                  </Link>
                  <span
                    className={`tooltip-container ${getLightColor(activeHeatpumpStatus)}`}
                    style={{ marginTop: "13px" }}
                  >
                    <span
                      className="tooltip-text"
                      style={{
                        margin: "-10px 26px"
                      }}
                    >
                      {getUnitStatusText(activeHeatpumpStatus)}
                    </span>
                  </span>
                </span>
              </div>
              <h3 id="naslov-pc" className="navbar-nav mx-auto">
                GTR Heatpumps
              </h3>
              <div
                className="navbar-nav ml-auto dropdown"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <button
                  type="submit"
                  className={
                    mainScreenShowing
                      ? `${mainScreenClass} btn btn-link dropdown-toggle nav-item nav-link`
                      : `${notMainScreenClass} btn btn-link dropdown-toggle nav-item nav-link`
                  }
                  id="navbarDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Profile
                </button>
                <div
                  className={
                    mainScreenShowing
                      ? `${mainScreenClass} dropdown-menu bg-primary`
                      : `${notMainScreenClass} dropdown-menu bg-primary`
                  }
                  aria-labelledby="navbarDropdown"
                >
                  <div
                    className={
                      mainScreenShowing
                        ? `${mainScreenClass} dropdown-item p-2`
                        : `${notMainScreenClass} dropdown-item p-2`
                    }
                  >
                    <Link
                      onClick={this.handleUpdateProfileData}
                      to="/profile"
                      className="nav-item nav-link"
                    >
                      Change Profile
                    </Link>
                  </div>
                  <div
                    className={
                      mainScreenShowing
                        ? `${mainScreenClass} dropdown-item p-2`
                        : `${notMainScreenClass} dropdown-item p-2`
                    }
                  >
                    <Link
                      onClick={this.handleChangeUserPassword}
                      to="/changepass"
                      className="nav-item nav-link"
                    >
                      Change Password
                    </Link>
                  </div>
                </div>
                <Link
                  id="logout-menu-link"
                  to="/login"
                  onClick={logout}
                  className="nav-item nav-link"
                >
                  Logout
                </Link>
              </div>
            </div>
          </nav>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  authentication: PropTypes.shape({}).isRequired,
  activeHeatpumpStatus: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  activeHeatpumpData: PropTypes.shape({ model: PropTypes.string }),
};

Header.defaultProps = {
  activeHeatpumpStatus: false,
  activeHeatpumpData: { name: '' },
};

function mapStateToProps(state) {
  const {
    authentication,
    heatpumps: { activeHeatpumpStatus, activeHeatpumpData },
  } = state;
  return {
    authentication,
    activeHeatpumpStatus,
    activeHeatpumpData,
  };
}

const connectedHeader = withRouter(connect(mapStateToProps)(Header));
export { connectedHeader as Header };
