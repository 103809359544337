import { heatpumpConstants } from "../_constants";

export function heatpumpUpdating(state = {}, action) {
  // console.log (state);
  switch (action.type) {
    case heatpumpConstants.UPDATE_HEATPUMP_REQUEST:
      return { updating: true };
    case heatpumpConstants.UPDATE_HEATPUMP_SUCCESS:
      return {};
    case heatpumpConstants.UPDATE_HEATPUMP_FAILURE:
      return {};
    default:
      return state;
  }
}
