import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { updating } from "./updating.reducer";
import { users } from "./users.reducer";
import { heatpumps } from "./heatpumps.reducer";
import { alert } from "./alert.reducer";
import { updatingUserData } from "./updatingUserData.reducer";
import { updatingHeatpumpData } from "./updatingHeatpumpData.reducer";
import { changePass } from "./changePass.reducer";
import { heatpumpRegistration } from "./heatpump.registration.reducer";
import { heatpumpUpdating } from "./heatpump.updating.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  updating,
  users,
  heatpumps,
  alert,
  updatingUserData,
  updatingHeatpumpData,
  changePass,
  heatpumpRegistration,
  heatpumpUpdating
});

export default rootReducer;
