import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { countryList } from "../_constants";
import {
  userActions,
  changeLoginData,
  changeUpdatingUserData
} from "../_actions";
import {
  MyInputText,
  MyInputEmail,
  MySelect,
  MyInputTel,
  MySubmitCancel
} from "../_forms";

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { updatingUserData, dispatch, currentUser } = this.props;
    if (
      !updatingUserData.id ||
      currentUser._id !== updatingUserData.id ||
      !updatingUserData.firstName
    ) {
      dispatch(
        changeUpdatingUserData({
          id: currentUser._id,
          gender: currentUser.gender,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
          company: currentUser.company,
          street: currentUser.street,
          zipcode: currentUser.zipcode,
          city: currentUser.city,
          country: currentUser.country,
          language: currentUser.language,
          phone: currentUser.phone,
          mobilePhone: currentUser.mobilePhone,
          username: currentUser.username,
          password: "",
          newPassword: "",
          prevPath: "/"
        })
      );
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { dispatch, updatingUserData } = this.props;

    dispatch(
      changeUpdatingUserData({
        ...updatingUserData,
        [name]: value
      })
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    const { dispatch, history, updatingUserData } = this.props;

    this.setState({ submitted: true });

    if (
      updatingUserData.id &&
      updatingUserData.gender &&
      updatingUserData.firstName &&
      updatingUserData.lastName &&
      updatingUserData.email &&
      updatingUserData.street &&
      updatingUserData.zipcode &&
      updatingUserData.city &&
      updatingUserData.country &&
      updatingUserData.language &&
      updatingUserData.phone &&
      updatingUserData.mobilePhone &&
      updatingUserData.username
    ) {
      dispatch(
        userActions.update(
          {
            id: updatingUserData.id,
            gender: updatingUserData.gender,
            firstName: updatingUserData.firstName,
            lastName: updatingUserData.lastName,
            email: updatingUserData.email,
            company: updatingUserData.company,
            street: updatingUserData.street,
            zipcode: updatingUserData.zipcode,
            city: updatingUserData.city,
            country: updatingUserData.country,
            language: updatingUserData.language,
            phone: updatingUserData.phone,
            mobilePhone: updatingUserData.mobilePhone,
            username: updatingUserData.username,
            prevPath: "/"
          },
          history,
          updatingUserData.prevPath
        )
      );
      dispatch(
        changeLoginData({
          id: updatingUserData.id,
          gender: updatingUserData.gender,
          firstName: updatingUserData.firstName,
          lastName: updatingUserData.lastName,
          email: updatingUserData.email,
          company: updatingUserData.company,
          street: updatingUserData.street,
          zipcode: updatingUserData.zipcode,
          city: updatingUserData.city,
          country: updatingUserData.country,
          language: updatingUserData.language,
          phone: updatingUserData.phone,
          mobilePhone: updatingUserData.mobilePhone,
          username: updatingUserData.username,
          prevPath: "/"
        })
      );
    }
  }

  render() {
    const { updating, history, updatingUserData, currentUser } = this.props;
    const { submitted } = this.state;
    return (
      <div className="col-sm-12">
        <h2 className="offset-xl-3">
          Update your profile {updatingUserData.username}:
        </h2>
        {updatingUserData &&
        updatingUserData.id &&
        currentUser._id === updatingUserData.id ? (
          <form name="form" onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div
                className="col-sm-3 offset-xl-3"
                style={{ minWidth: "240px" }}
              >
                <MySelect
                  value={updatingUserData.gender}
                  name="gender"
                  eventHandler={this.handleChange}
                  optionsArray={["Ms.", "Mr."]}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.firstName}
                  inputName="firstName"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.lastName}
                  inputName="lastName"
                  eventHandler={this.handleChange}
                />
                <MyInputEmail
                  submitted={submitted}
                  inputValue={updatingUserData.email}
                  inputName="email"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.street}
                  inputName="street"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.zipcode}
                  inputName="zipcode"
                  eventHandler={this.handleChange}
                />
              </div>
              <div className="col-sm-6" style={{ minWidth: "240px" }}>
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.city}
                  inputName="city"
                  eventHandler={this.handleChange}
                />
                <MySelect
                  value={updatingUserData.country}
                  name="country"
                  eventHandler={this.handleChange}
                  optionsArray={countryList}
                />
                <MySelect
                  value={updatingUserData.language}
                  name="language"
                  eventHandler={this.handleChange}
                  optionsArray={["English"]}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.company}
                  inputName="company"
                  eventHandler={this.handleChange}
                />
                <MyInputTel
                  submitted={submitted}
                  inputValue={updatingUserData.phone}
                  inputName="phone"
                  eventHandler={this.handleChange}
                />
                <MyInputTel
                  submitted={submitted}
                  inputValue={updatingUserData.mobilePhone}
                  inputName="mobilePhone"
                  eventHandler={this.handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-6 offset-xl-3">
                <MySubmitCancel
                  ongoingAction={updating}
                  submitButtonLabel="Update"
                  cancelExists
                  eventHandler={() => history.goBack()}
                />
              </div>
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
    );
  }
}

ProfilePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  updating: PropTypes.bool,
  updatingUserData: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired
};

ProfilePage.defaultProps = {
  updating: false
};

function mapStateToProps(state) {
  const { updating } = state.updating;
  const { updatingUserData, authentication } = state;
  const currentUser = authentication.user;
  return {
    updating,
    updatingUserData,
    currentUser
  };
}

const connectedRegisterPage = withRouter(connect(mapStateToProps)(ProfilePage));
export { connectedRegisterPage as ProfilePage };
