import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Role } from "../_helpers";
import { userActions, changeUpdatingUserData } from "../_actions";

class UsersPage extends React.Component {
  componentDidMount() {
    this.updateUsersList();
  }

  updateUsersList() {
    const { dispatch, currentUser } = this.props;

    if (currentUser.role === Role.Manufacturer) {
      dispatch(userActions.getAll());
    } else if (currentUser.role === Role.Partner) {
      dispatch(userActions.getByOwner(currentUser.username));
    }
  }

  handleUpdateUserData(user) {
    const { dispatch } = this.props;
    // console.log(user);
    dispatch(
      changeUpdatingUserData({
        ...user,
        prevPath: "/users"
      })
    );
  }

  handleRegister() {
    const { dispatch, currentUser } = this.props;
    dispatch(
      changeUpdatingUserData({
        gender: "Mr.",
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        street: "",
        zipcode: "",
        city: "",
        country: "Serbia",
        language: "English",
        phone: "",
        mobilePhone: "",
        role: "User",
        owner: currentUser.username,
        username: "",
        password: ""
      })
    );
  }

  userUpdating(user) {
    if (user.updating) return <em>Updating...</em>;
    if (user.updateError)
      return <span className="text-danger">ERROR: {user.updateError}</span>;
    return (
      <span>
        <Link
          onClick={() => this.handleUpdateUserData(user)}
          to={`/update/${user._id}`}
        >
          Update
        </Link>
      </span>
    );
  }

  /* userDeleting(user) {
    if (user.deleting) return <em>Deleting...</em>;
    if (user.deleteError)
      return <span className="text-danger">ERROR: {user.deleteError}</span>;
    return (
      <span>
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={() => this.handleDeleteUser(user.id)}
        >
          Delete
        </button>
      </span>
    );
  } */

  render() {
    const { currentUser, users } = this.props;
    let counter = 0;
    function incrementCounter() {
      counter += 1;
      return counter;
    }
    return (
      <div className="row">
        <div className="col col-md-2">
          <Link onClick={() => this.handleRegister()} to="/register">
            Register new user
          </Link>
          <br />
          <br />
        </div>
        <div className="col col-md-6 offset-lg-1">
          <h3>All registered users:</h3>
          {users.loading && <em>Loading users...</em>}
          {users.error && (
            <span className="text-danger">ERROR: {users.error}</span>
          )}
          {users.items && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th className="col-street" scope="col">
                    Street
                  </th>
                  <th className="col-city" scope="col">
                    City
                  </th>
                  <th className="col-email" scope="col">
                    E-mail
                  </th>
                  <th scope="col">Update</th>
                </tr>
              </thead>
              <tbody>
                {users.items.map(user =>
                  currentUser._id !== user.id ? (
                    <tr key={user.id}>
                      <th scope="row">{incrementCounter()}</th>
                      <td>{`${user.lastName} ${user.firstName}`}</td>
                      <td className="col-street">{user.street}</td>
                      <td className="col-city">{user.city}</td>
                      <td className="col-email">
                        <a href={`mailto:${user.email}`}>{user.email}</a>
                      </td>
                      <td>{this.userUpdating(user)}</td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

UsersPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  users: PropTypes.shape({}).isRequired
};

function mapStateToProps(state) {
  const { users, authentication } = state;
  const currentUser = authentication.user;
  return {
    currentUser,
    users
  };
}

const connectedUsersPage = connect(mapStateToProps)(UsersPage);
export { connectedUsersPage as UsersPage };
