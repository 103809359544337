import { heatpumpConstants } from "../_constants";

export function updatingHeatpumpData(state = {}, action) {
  switch (action.type) {
    case heatpumpConstants.UPDATING_HEATPUMP_DATA:
      return action.heatpump ? action.heatpump : {};
    case heatpumpConstants.CLEAR_UPDATING_HEATPUMP_DATA:
      return {};
    default:
      return state;
  }
}
