import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  submitted: PropTypes.bool.isRequired,
  ownerExists: PropTypes.bool.isRequired,
  inputValue: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  eventHandler: PropTypes.func.isRequired
};

export function MyInputOwner({
  submitted,
  ownerExists,
  inputValue,
  inputName,
  eventHandler
}) {
  const inputNameForUser = inputName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, str => str.toUpperCase());
  return (
    <div
      className={`form-group${submitted && !ownerExists ? " text-danger" : ""}`}
    >
      <label htmlFor={inputName}>
        {inputNameForUser}
        <input
          type="text"
          className="form-control"
          name={inputName}
          value={inputValue}
          onChange={eventHandler}
          placeholder={`Enter your ${inputNameForUser}`}
          autoComplete="new-password"
        />
      </label>
      {submitted && !ownerExists && (
        <div className="help-block">
          {inputNameForUser} doesn{"'"}t exist
        </div>
      )}
    </div>
  );
}

MyInputOwner.propTypes = propTypes;
