import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  submitted: PropTypes.bool.isRequired,
  inputValue: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  eventHandler: PropTypes.func.isRequired
};

export function MyInputPassword({
  submitted,
  inputValue,
  inputName,
  eventHandler
}) {
  const inputNameForUser = inputName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, str => str.toUpperCase());
  return (
    <div
      className={`form-group${submitted && !inputValue ? " text-danger" : ""}`}
    >
      <label htmlFor={inputName}>
        {inputNameForUser}
        <input
          type="password"
          className="form-control"
          name={inputName}
          value={inputValue}
          onChange={eventHandler}
          autoComplete="new-password"
          placeholder={`Enter your ${inputNameForUser}`}
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
        />
      </label>
      {submitted && !inputValue && (
        <div className="help-block">{inputNameForUser} is required</div>
      )}
    </div>
  );
}

MyInputPassword.propTypes = propTypes;
MyInputPassword.defaultProps = {
  inputValue: ""
};
