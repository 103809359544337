export const heatpumpConstants = {
  REGISTER_HEATPUMP_REQUEST: "REGISTER_HEATPUMP_REQUEST",
  REGISTER_HEATPUMP_SUCCESS: "REGISTER_HEATPUMP_SUCCESS",
  REGISTER_HEATPUMP_FAILURE: "REGISTER_HEATPUMP_FAILURE",

  GETALL_HEATPUMPS_REQUEST: "GETALL_HEATPUMPS_REQUEST",
  GETALL_HEATPUMPS_SUCCESS: "GETALL_HEATPUMPS_SUCCESS",
  GETALL_HEATPUMPS_FAILURE: "GETALL_HEATPUMPS_FAILURE",

  GET_CONNECTING_HEATPUMPS_REQUEST: "GET_CONNECTING_HEATPUMPS_REQUEST",
  GET_CONNECTING_HEATPUMPS_SUCCESS: "GET_CONNECTING_HEATPUMPS_SUCCESS",
  GET_CONNECTING_HEATPUMPS_FAILURE: "GET_CONNECTING_HEATPUMPS_FAILURE",

  DELETE_HEATPUMP_REQUEST: "DELETE_HEATPUMP_REQUEST",
  DELETE_HEATPUMP_SUCCESS: "DELETE_HEATPUMP_SUCCESS",
  DELETE_HEATPUMP_FAILURE: "DELETE_HEATPUMP_FAILURE",

  UPDATE_HEATPUMP_REQUEST: "UPDATE_HEATPUMP_REQUEST",
  UPDATE_HEATPUMP_SUCCESS: "UPDATE_HEATPUMP_SUCCESS",
  UPDATE_HEATPUMP_FAILURE: "UPDATE_HEATPUMP_FAILURE",

  HEATPUMP_CONNECTION_REQUEST: "HEATPUMP_CONNECTION_REQUEST",
  HEATPUMP_CONNECTION_SUCCESS: "HEATPUMP_CONNECTION_SUCCESS",
  HEATPUMP_CONNECTION_FAILURE: "HEATPUMP_CONNECTION_FAILURE",

  ALL_HEATPUMP_CONNECTION_REQUEST: "ALL_HEATPUMP_CONNECTION_REQUEST",
  SET_HEATPUMP_UNIT_STATUS: "SET_HEATPUMP_UNIT_STATUS",
  SET_ACTIVE_HEATPUMP_UNIT_STATUS: "SET_ACTIVE_HEATPUMP_UNIT_STATUS",
  ALL_HEATPUMP_CONNECTION_FAILURE: "ALL_HEATPUMP_CONNECTION_FAILURE",
  ACTIVE_HEATPUMP_CONNECTION_FAILURE: "ACTIVE_HEATPUMP_CONNECTION_FAILURE",

  UPDATING_HEATPUMP_DATA: "UPDATING_HEATPUMP_DATA",
  CLEAR_UPDATING_HEATPUMP_DATA: "CLEAR_UPDATING_HEATPUMP_DATA",

  CLEAR_HEATPUMPS: "CLEAR_HEATPUMPS"
};
