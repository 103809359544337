import { heatpumpConstants } from "../_constants";

export function heatpumpRegistration(state = {}, action) {
  switch (action.type) {
    case heatpumpConstants.REGISTER_HEATPUMP_REQUEST:
      return { registering: true };
    case heatpumpConstants.REGISTER_HEATPUMP_SUCCESS:
      return {};
    case heatpumpConstants.REGISTER_HEATPUMP_FAILURE:
      return {};
    default:
      return state;
  }
}
