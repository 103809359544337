/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { heatpumpConstants, userConstants } from "../_constants";
import { fetchService } from "../_services";
import { alertActions, changeUpdatingHeatpumpData } from ".";
// import { Role } from "../_helpers";

function clearHeatpumps() {
  return { type: heatpumpConstants.CLEAR_HEATPUMPS };
}

function clearUpdateHeatpumpData() {
  fetchService.clearUpdateHeatpumpData();
  return { type: heatpumpConstants.CLEAR_UPDATING_HEATPUMP_DATA };
}

function register(heatpump, history) {
  function request(heatpump) {
    return { type: heatpumpConstants.REGISTER_HEATPUMP_REQUEST, heatpump };
  }
  function success(heatpump) {
    return { type: heatpumpConstants.REGISTER_HEATPUMP_SUCCESS, heatpump };
  }
  function failure(error) {
    return { type: heatpumpConstants.REGISTER_HEATPUMP_FAILURE, error };
  }
  return dispatch => {
    dispatch(request(heatpump));

    fetchService.register(heatpump, "heatpumps").then(
      heatpump => {
        dispatch(success(heatpump));
        history.push("/heatpump");
        dispatch(alertActions.success("Registration successful"));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function update(heatpump, history, prevPath) {
  function request() {
    return { type: heatpumpConstants.UPDATE_HEATPUMP_REQUEST };
  }
  function success() {
    return { type: heatpumpConstants.UPDATE_HEATPUMP_SUCCESS };
  }
  function failure() {
    return { type: heatpumpConstants.UPDATE_HEATPUMP_FAILURE };
  }
  return dispatch => {
    dispatch(request());

    fetchService.update(heatpump, "heatpumps").then(
      () => {
        dispatch(success());
        history.push(prevPath);
        dispatch(alertActions.success("Update successful"));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function checkHeatpumpUnitStatus(ipAddress, id, activeHeatPump = false) {
  function request() {
    return { type: heatpumpConstants.ALL_HEATPUMP_CONNECTION_REQUEST };
  }
  function success(data) {
    // console.log("Success in ALL_ fetch over proxy!");
    const parsedData = JSON.parse(data)
    // console.log('aaa', id, data, parsedData[0].value)
    return {
      type: heatpumpConstants.SET_HEATPUMP_UNIT_STATUS,
      id,
      unitStatus: parsedData && parsedData.length ? parsedData[0].value : false,
    };
  }
  function successActive(data) {
    // console.log("Success in ALL_ fetch over proxy!");
    const parsedData = JSON.parse(data)
    return {
      type: heatpumpConstants.SET_ACTIVE_HEATPUMP_UNIT_STATUS,
      activeHeatpumpStatus: parsedData && parsedData.length ? parsedData[0].value : false,
    };
  }
  function failure() {
    // console.log("Error in ALL_ fetch over proxy!");
    return { type: heatpumpConstants.ALL_HEATPUMP_CONNECTION_FAILURE };
  }
  function failureActive() {
    // console.log("Error in ALL_ fetch over proxy!");
    return { type: heatpumpConstants.ACTIVE_HEATPUMP_CONNECTION_FAILURE };
  }
  return dispatch => {
    dispatch(request());

    fetchService
      .checkAllClientConnection(ipAddress)
      .then(
        data => dispatch(activeHeatPump ? successActive(data) : success(data)),
        () => dispatch(activeHeatPump ? failureActive() : failure())
      );
  };
}

function checkAllHeatpumpsConnections(heatpumps, dispatch) {
  // console.log(heatpumps);
  if (heatpumps) {
    heatpumps.forEach(heatpump => {
      if (heatpump.ipAddress) {
        // console.log("HP IP: ", heatpump.ipAddress);
        dispatch(checkHeatpumpUnitStatus(heatpump.ipAddress, heatpump._id));
      }
    });
  }
}

function getAll(checkConnection) {
  function request() {
    return { type: heatpumpConstants.GETALL_HEATPUMPS_REQUEST };
  }
  function success(heatpumps, dispatch) {
    if (checkConnection) {
      checkAllHeatpumpsConnections(heatpumps, dispatch);
    }
    return { type: heatpumpConstants.GETALL_HEATPUMPS_SUCCESS, heatpumps };
  }
  function failure(error) {
    return { type: heatpumpConstants.GETALL_HEATPUMPS_FAILURE, error };
  }
  return dispatch => {
    dispatch(request());

    fetchService
      .getAll("heatpumps")
      .then(
        heatpumps => dispatch(success(heatpumps, dispatch)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function getByOwner(owner, checkConnection) {
  function request() {
    return { type: heatpumpConstants.GETALL_HEATPUMPS_REQUEST };
  }
  function success(heatpumps, dispatch) {
    if (checkConnection) {
      checkAllHeatpumpsConnections(heatpumps, dispatch);
    }
    return { type: heatpumpConstants.GETALL_HEATPUMPS_SUCCESS, heatpumps };
  }
  function failure(error) {
    return { type: heatpumpConstants.GETALL_HEATPUMPS_FAILURE, error };
  }
  return dispatch => {
    dispatch(request());

    fetchService
      .getByOwner(owner, "heatpumps")
      .then(
        heatpumps => dispatch(success(heatpumps, dispatch)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function getAllPartnersUsersHeatpumps(owners, checkConnection) {
  function request() {
    return { type: heatpumpConstants.GETALL_HEATPUMPS_REQUEST };
  }
  function success(heatpumps, dispatch) {
    if (checkConnection) {
      checkAllHeatpumpsConnections(heatpumps, dispatch);
    }
    return { type: heatpumpConstants.GETALL_HEATPUMPS_SUCCESS, heatpumps };
  }
  function failure(error) {
    return { type: heatpumpConstants.GETALL_HEATPUMPS_FAILURE, error };
  }
  return dispatch => {
    dispatch(request());

    fetchService
      .getByAllOwners(owners, "heatpumps")
      .then(
        heatpumps => dispatch(success(heatpumps, dispatch)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function getAllPartnersUsers(owner, checkConnection) {
  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users, dispatch) {
    const ownersUsers = users.map(user => {
      return user.owner === owner && user.username;
    });
    ownersUsers.push(owner);
    dispatch(getAllPartnersUsersHeatpumps(ownersUsers, checkConnection));

    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
  return dispatch => {
    dispatch(request());

    fetchService
      .getAll("users")
      .then(
        users => dispatch(success(users, dispatch)),
        error => dispatch(failure(error.toString()))
      );
  };
}

/* function getDataAfterRefresh(id, dispatch, currentPath) {
  function request() {
    return { type: userConstants.UPDATE_LOGIN_DATA_REQUEST };
  }

  function success(user) {
    if (currentPath === "/users") {
      if (user.role === Role.Manufacturer) {
        dispatch(getAll());
      } else if (user.role === Role.Partner) {
        dispatch(getByOwner(user.username));
      }
    }

    return { type: userConstants.UPDATE_LOGIN_DATA, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());
    fetchService
      .getById(id)
      .then(
        user => dispatch(success(user)),
        error => dispatch(failure(error.toString()))
      );
  };
} */

function getUpdateDataAfterRefresh(dispatch, currentPath) {
  /* function request() {
    return { type: heatpumpConstants.UPDATE_HEATPUMP_REQUEST };
  } */

  function success(heatpump) {
    dispatch(
      changeUpdatingHeatpumpData({
        id: heatpump.id,
        serial: heatpump.serial,
        name: heatpump.name,
        street: heatpump.street,
        zipcode: heatpump.zipcode,
        city: heatpump.city,
        country: heatpump.country,
        model: heatpump.model,
        ipAddress: heatpump.ipAddress,
        owner: heatpump.owner,
        prevPath: "/heatpump"
      })
    );

    return { type: heatpumpConstants.UPDATE_HEATPUMP_SUCCESS };
  }

  function failure(error) {
    return { type: heatpumpConstants.UPDATE_HEATPUMP_FAILURE, error };
  }

  return dispatch => {
    // dispatch(request());
    fetchService
      .getById(currentPath.replace("/update-heatpump/", ""), "heatpumps")
      .then(
        heatpump => dispatch(success(heatpump)),
        error => dispatch(failure(error))
      );
  };
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, history, prevPath) {
  function request(id) {
    return { type: heatpumpConstants.DELETE_HEATPUMP_REQUEST, id };
  }
  function success(id) {
    return { type: heatpumpConstants.DELETE_HEATPUMP_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: heatpumpConstants.DELETE_HEATPUMP_FAILURE, id, error };
  }
  return dispatch => {
    dispatch(request(id));

    fetchService.delete(id, "heatpumps").then(
      () => {
        dispatch(success(id));
        history.push(prevPath);
        dispatch(alertActions.success("Delete successful"));
      },
      error => {
        dispatch(failure(id, error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function checkClientConnection(ipAddress, id) {
  function request() {
    return { type: heatpumpConstants.HEATPUMP_CONNECTION_REQUEST };
  }
  function success(dispatch) {
    // console.log("Success in fetch over proxy!", successText);
    dispatch(checkHeatpumpUnitStatus(ipAddress, id, true))
    return { type: heatpumpConstants.HEATPUMP_CONNECTION_SUCCESS };
  }
  function failure(error) {
    // console.log("Error in fetch over proxy!");
    return { type: heatpumpConstants.HEATPUMP_CONNECTION_FAILURE, error };
  }
  return dispatch => {
    dispatch(request());

    fetchService
      .checkClientConnection(ipAddress)
      .then(
        successText => dispatch(success(dispatch)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function getByIdAndCheckConnection(id) {
  function request() {
    return { type: heatpumpConstants.GET_CONNECTING_HEATPUMPS_REQUEST };
  }
  function success(heatpump, dispatch) {
    // console.log(heatpump.ipAddress);
    if (heatpump.ipAddress) {
      dispatch(checkClientConnection(heatpump.ipAddress, id));
    }

    return {
      type: heatpumpConstants.GET_CONNECTING_HEATPUMPS_SUCCESS,
      heatpump
    };
  }
  function failure(error) {
    return { type: heatpumpConstants.GET_CONNECTING_HEATPUMPS_FAILURE, error };
  }
  return dispatch => {
    dispatch(request());

    fetchService
      .getById(id, "heatpumps")
      .then(
        heatpump => dispatch(success(heatpump, dispatch)),
        error => dispatch(failure(error.toString()))
      );
  };
}

export const heatpumpActions = {
  register,
  getAll,
  delete: _delete,
  update,
  clearHeatpumps,
  clearUpdateHeatpumpData,
  getByOwner,
  getAllPartnersUsers,
  getAllPartnersUsersHeatpumps,
  getUpdateDataAfterRefresh,
  checkClientConnection,
  getByIdAndCheckConnection
};
