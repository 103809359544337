import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

/* export const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = rest.user || JSON.parse(localStorage.getItem("user"));

  return (
    <Route
      {...rest}
      render={renderProps => {
        return user ? (
          <Component {...renderProps} {...rest} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: renderProps.location } }}
          />
        );
      }}
    />
  );
}; */

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={renderProps => {
        return <Component {...renderProps} {...rest} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
};
