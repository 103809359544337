import "whatwg-fetch";
import Promise from "promise-polyfill";
import { config } from "../_constants";
import { authHeader } from "../_helpers";

// To add to window
if (!window.Promise) {
  window.Promise = Promise;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function handleProxyResponse(response) {
  return response.text().then(data => {
    // const data = URL.createObjectURL(myBlob);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      // console.log("response not OK");

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    // console.log("response OK");

    return data;
  });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function login(username, password, changingPassword) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password, changingPassword })
  };

  return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (!changingPassword)
        localStorage.setItem(
          "user",
          JSON.stringify({ _id: user._id, token: user.token })
        );

      return user;
    });
  // .catch(error => window.alert(error));
}

function clearUpdateUserData() {
  localStorage.removeItem("updatingUser");
}

function clearUpdateHeatpumpData() {
  localStorage.removeItem("updatingHeatpump");
}

function getAll(subpath) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/${subpath}`, requestOptions).then(
    handleResponse
  );
}

function getByOwner(owner, subpath) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/${subpath}/ownersdata/${owner}`,
    requestOptions
  ).then(handleResponse);
}

function getByAllOwners(owners, subpath) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(owners)
  };

  return fetch(
    `${config.apiUrl}/${subpath}/allownersdata`,
    requestOptions
  ).then(handleResponse);
}

function getById(id, subpath) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/${subpath}/${subpath}id/${id}`,
    requestOptions
  ).then(handleResponse);
}

function register(entity, subpath) {
  // debugger;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(entity)
  };

  // const path = `${config.apiUrl}/${subpath}/register`;
  // debugger;
  return fetch(`${config.apiUrl}/${subpath}/register`, requestOptions).then(
    handleResponse
  );
}

function update(entity, subpath) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(entity)
  };

  return fetch(`${config.apiUrl}/${subpath}/${entity.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, subpath) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/${subpath}/${id}`, requestOptions).then(
    handleResponse
  );
}

function checkClientConnection(ipAddress) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "text/html"
    },
    cache: "no-cache" // default not good for repeated fetching
  };

  // console.log("ipAddress: ", ipAddress);

  return fetch(
    `${config.apiUrl}/userinfo.cfg?targetIp=${ipAddress}`,
    requestOptions
  ).then(handleProxyResponse);
}

function checkAllClientConnection(ipAddress) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "text/html"
    },
    cache: "no-cache" // default not good for repeated fetching
  };

  // console.log("ipAddress: ", ipAddress);

  return fetch(
    `${config.apiUrl}/heatpumps/ping/${ipAddress}`,
    requestOptions
  ).then(handleProxyResponse);
}

function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email })
  };

  return fetch(`${config.apiUrl}/users/forgot_password`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user;
    });
}

function resetPasswords(id, token, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id, token, password })
  };

  return fetch(`${config.apiUrl}/users/reset_password`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user;
    });
}

export const fetchService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  clearUpdateUserData,
  clearUpdateHeatpumpData,
  getByOwner,
  getByAllOwners,
  checkClientConnection,
  checkAllClientConnection,
  forgotPassword,
  resetPasswords
};
