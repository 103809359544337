export const getLightColor = unitStatus => {
    let ledLight
    switch (unitStatus) {
        case false:
        case undefined:
            ledLight = "led-grey"
            break;
        case 5:
            ledLight = "led-red"
            break;
        case true:
        default:
            ledLight = "led-green"
            break;
    }
    return ledLight
}

export const getUnitStatusText = unitStatus => {
    let status
    switch (unitStatus) {
        case 1:
            status = "No Request"
            break
        case 2:
            status = "Heating"
            break
        case 3:
            status = "Cooling"
            break
        case 4:
            status = "DHW Preparing"
            break
        case 5:
            status = "OFF by Alarm"
            break
        case 6:
            status = "No Demand"
            break
        case 7:
            status = "Standing by"
            break
        case 8:
            status = "Turning Off"
            break
        case 9:
            status = "OFF by Ext. DI"
            break
        case 10:
            status = "Off by Keypad"
            break
        case 11:
            status = "Switched Off"
            break
        case 12:
            status = "Off by Sched."
            break
        case 13:
            status = "Off by BMS"
            break
        case 14:
            status = "Off by Altern."
            break
        case 15:
            status = "Off by Es lev."
            break
        case 16:
            status = "Working"
            break
        case true:
            status = "Connected"
            break
        case false:
        default:
            status = "No Connection"
            break
    }
    return status
}