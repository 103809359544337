import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  eventHandler: PropTypes.func.isRequired,
  optionsArray: PropTypes.arrayOf(PropTypes.string).isRequired
};

export function MySelect({ value, name, eventHandler, optionsArray }) {
  const nameForUser = name
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, str => str.toUpperCase());
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {nameForUser}
        <select
          className="form-control"
          name={name}
          value={value}
          onChange={eventHandler}
        >
          {optionsArray.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}

MySelect.propTypes = propTypes;
MySelect.defaultProps = {
  value: ""
};
