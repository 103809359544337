import { heatpumpConstants } from "../_constants";

export function heatpumps(state = {}, action) {
  // console.log (state);
  switch (action.type) {
    case heatpumpConstants.GETALL_HEATPUMPS_REQUEST:
      return { ...state, loading: true };
    case heatpumpConstants.GETALL_HEATPUMPS_SUCCESS:
      return { ...state, items: action.heatpumps, loading: false };
    case heatpumpConstants.GETALL_HEATPUMPS_FAILURE:
      return { ...state, error: action.error, items: {}, loading: false };

    case heatpumpConstants.GET_CONNECTING_HEATPUMPS_REQUEST:
      return { ...state, loading: true };
    case heatpumpConstants.GET_CONNECTING_HEATPUMPS_SUCCESS:
      return { ...state, loading: false, activeHeatpumpData: action.heatpump };
    case heatpumpConstants.GET_CONNECTING_HEATPUMPS_FAILURE:
      return { ...state, loading: false };
    case heatpumpConstants.DELETE_HEATPUMP_REQUEST:
      // add 'deleting:true' property to heatpump being deleted
      return {
        ...state,
        items: state.items.map(heatpump =>
          heatpump.id === action.id ? { ...heatpump, deleting: true } : heatpump
        )
      };
    case heatpumpConstants.DELETE_HEATPUMP_SUCCESS:
      // remove deleted heatpump from state
      // debugger;
      // console.log(state);
      return {
        ...state,
        items: state.items.filter(heatpump => heatpump.id !== action.id)
      };
    case heatpumpConstants.DELETE_HEATPUMP_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to heatpump
      return {
        ...state,
        items: state.items.map(heatpump => {
          if (heatpump.id === action.id) {
            // make copy of heatpump without 'deleting:true' property
            const { deleting, ...heatpumpCopy } = heatpump;
            // return copy of heatpump with 'deleteError:[error]' property
            return { ...heatpumpCopy, deleteError: action.error };
          }

          return heatpump;
        })
      };
    case heatpumpConstants.UPDATE_HEATPUMP_REQUEST:
      // add 'UPDATING:true' property to heatpump being updated
      if (state.items) {
        return {
          ...state,
          items: state.items.map(heatpump =>
            heatpump.id === action.id
              ? { ...heatpump, updating: true }
              : heatpump
          )
        };
      }
      return {};
    case heatpumpConstants.UPDATE_HEATPUMP_SUCCESS:
      // update heatpump in state ( no action in here?????? )
      if (state.items) {
        return {
          ...state,
          items: state.items.map(heatpump =>
            heatpump.id === action.id ? { heatpump: action } : heatpump
          )
        };
      }
      return {};
    case heatpumpConstants.UPDATE_HEATPUMP_FAILURE:
      // remove 'updating:true' property and add 'updateError:[error]' property to heatpump
      return {
        ...state,
        items: state.items.map(heatpump => {
          if (heatpump.id === action.id) {
            // make copy of heatpump without 'updating:true' property
            const { updating, ...heatpumpCopy } = heatpump;
            // return copy of heatpump with 'deleteError:[error]' property
            return { ...heatpumpCopy, updateError: action.error };
          }

          return heatpump;
        })
      };
    case heatpumpConstants.HEATPUMP_CONNECTION_REQUEST:
      return { ...state, checkingConnection: true };
    case heatpumpConstants.HEATPUMP_CONNECTION_SUCCESS:
      return {
        ...state,
        checkingConnection: false,
        activeHeatpumpStatus: true,
      };
    case heatpumpConstants.SET_ACTIVE_HEATPUMP_UNIT_STATUS:
      return {
        ...state,
        activeHeatpumpStatus: +action.activeHeatpumpStatus,
      };
    case heatpumpConstants.HEATPUMP_CONNECTION_FAILURE:
      return {
        ...state,
        checkingConnection: false,
        activeHeatpumpStatus: false
      };

    /*  */
    case heatpumpConstants.ALL_HEATPUMP_CONNECTION_REQUEST:
      return { ...state, checkingConnection: true };
    case heatpumpConstants.SET_HEATPUMP_UNIT_STATUS:
      if (state.items) {
        return {
          ...state,
          checkingConnection: false,
          items: state.items.map(heatpump =>
            heatpump.id === action.id
              ? { ...heatpump, unitStatus: +action.unitStatus }
              : heatpump
          )
        };
      }
      return {};

    case heatpumpConstants.ALL_HEATPUMP_CONNECTION_FAILURE:
      if (state.items) {
        return {
          ...state,
          checkingConnection: false,
          items: state.items.map(heatpump =>
            heatpump.id === action.id
              ? { ...heatpump, unitStatus: false }
              : heatpump
          )
        };
      }
      return {};
    /*  */

    case heatpumpConstants.CLEAR_HEATPUMPS:
      return {};
    default:
      return state;
  }
}
