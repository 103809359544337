import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  changeUpdatingHeatpumpData,
  userActions,
  changeLoginData
} from "../_actions";
import { updateHeatpumpsList } from ".";
import { getLightColor, getUnitStatusText, Role } from "../_helpers";

class HeatpumpsPage extends React.Component {
  componentDidMount() {
    const { dispatch, currentUser } = this.props;
    updateHeatpumpsList(dispatch, currentUser);
  }

  handleTableRowClick = (heatpumpId, event) => {
    const { dispatch, currentUser } = this.props;
    if (!event.target.href || !event.target.href.includes("update-heatpump")) {
      dispatch(
        userActions.update({
          id: currentUser._id,
          activeHeatpump: heatpumpId
        })
      );
      dispatch(
        changeLoginData({
          activeHeatpump: heatpumpId
        })
      );
    }
  };

  handleUpdateHeatpumpData(heatpump) {
    const { dispatch } = this.props;
    // console.log(heatpump);
    dispatch(
      changeUpdatingHeatpumpData({
        ...heatpump,
        prevPath: "/heatpump"
      })
    );
  }

  handleRegister() {
    const { dispatch, currentUser } = this.props;
    dispatch(
      changeUpdatingHeatpumpData({
        serial: "",
        name: "",
        street: "",
        zipcode: "",
        city: "",
        country: "Serbia",
        model: "",
        ipAddress: "",
        owner: currentUser.username,
        registeringKey: ""
      })
    );
  }

  heatpumpUpdating(heatpump) {
    if (heatpump.updating) return <em>Updating...</em>;
    if (heatpump.updateError)
      return <span className="text-danger">ERROR: {heatpump.updateError}</span>;
    return (
      <span>
        <Link
          onClick={() => this.handleUpdateHeatpumpData(heatpump)}
          to={`/update-heatpump/${heatpump._id}`}
        >
          Update
        </Link>
      </span>
    );
  }

  /* heatpumpDeleting(heatpump) {
    if (heatpump.deleting) return <em>Deleting...</em>;
    if (heatpump.deleteError)
      return <span className="text-danger">ERROR: {heatpump.deleteError}</span>;
    return (
      <span>
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={() => this.handleDeleteHeatpump(heatpump.id)}
        >
          Delete
        </button>
      </span>
    );
  } */

  render() {
    const { heatpumps, users, currentUser } = this.props;
    let counter = 0;
    function incrementCounter() {
      counter += 1;
      return counter;
    }

    return (
      <div className="row">
        <div className="col col-md-2">
          {(currentUser.role === Role.Manufacturer ||
            currentUser.role === Role.Partner) && (
              <>
                <Link
                  onClick={() => this.handleRegister()}
                  to="/register-heatpump"
                >
                  Register new heatpump
                </Link>
                <br />
                <br />
              </>
            )}
        </div>
        <div className="col col-md-6 offset-lg-1">
          <h3>All registered heatpumps:</h3>
          {heatpumps.loading && <em>Loading heatpumps...</em>}
          {heatpumps.error && (
            <span className="text-danger">ERROR: {heatpumps.error}</span>
          )}
          {heatpumps.items && heatpumps.items.length > 0 && !users.loading && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th className="col-owner" scope="col">
                    Owner
                  </th>
                  <th className="col-street" scope="col">
                    Street
                  </th>
                  <th className="col-city" scope="col">
                    City
                  </th>
                  <th scope="col">Online</th>
                  <th scope="col">Update</th>
                </tr>
              </thead>
              <tbody>
                {heatpumps.items.map(heatpump => (
                  <tr
                    key={heatpump.id}
                    className={
                      currentUser.activeHeatpump === heatpump.id
                        ? "table-active"
                        : ""
                    }
                    onClick={() =>
                      this.handleTableRowClick(heatpump.id, window.event)
                    }
                  >
                    <th scope="row">{incrementCounter()}</th>
                    <td>
                      <Link
                        to="/mainscreen"
                      >
                        {heatpump.name}
                      </Link>
                    </td>
                    <td className="col-owner">{heatpump.owner}</td>
                    <td className="col-street">{heatpump.street}</td>
                    <td className="col-city">{heatpump.city}</td>
                    <td className="tooltip-container">
                      <span className="tooltip-text">{getUnitStatusText(heatpump.unitStatus)}</span>
                      <span
                        style={{ marginLeft: "19px" }}
                        className={getLightColor(heatpump.unitStatus)}
                      />
                    </td>
                    <td>{this.heatpumpUpdating(heatpump)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div >
    );
  }
}

HeatpumpsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  heatpumps: PropTypes.shape({}).isRequired,
  users: PropTypes.shape({}).isRequired
};

function mapStateToProps(state) {
  const { heatpumps, authentication, users } = state;
  const currentUser = authentication.user;
  return {
    currentUser,
    heatpumps,
    users
  };
}

const connectedHeatpumpsPage = connect(mapStateToProps)(HeatpumpsPage);
export { connectedHeatpumpsPage as HeatpumpsPage };
