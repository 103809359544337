import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { userActions, changeUpdatingUserData } from "../_actions";
import { Role } from "../_helpers";
import { countryList } from "../_constants";
import {
  MyInputText,
  MyInputEmail,
  MySelect,
  MyInputTel,
  MyInputOwner,
  MyInputPassword,
  MySubmitCancel
} from "../_forms";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      ownerExists: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { updatingUserData, dispatch, currentUser } = this.props;
    if (!updatingUserData.owner) {
      dispatch(
        changeUpdatingUserData({
          gender: "Mr.",
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          street: "",
          zipcode: "",
          city: "",
          country: "Serbia",
          language: "English",
          phone: "",
          mobilePhone: "",
          role: "User",
          owner: currentUser.username,
          username: "",
          password: ""
        })
      );
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { dispatch, updatingUserData } = this.props;

    dispatch(
      changeUpdatingUserData({
        ...updatingUserData,
        [name]: value
      })
    );

    this.setState({
      ownerExists: true
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { dispatch, history, updatingUserData } = this.props;
    const ownerExists = this.ownerExists();

    this.setState({ submitted: true, ownerExists });

    if (
      updatingUserData.gender &&
      updatingUserData.firstName &&
      updatingUserData.lastName &&
      updatingUserData.email &&
      updatingUserData.street &&
      updatingUserData.zipcode &&
      updatingUserData.city &&
      updatingUserData.country &&
      updatingUserData.language &&
      updatingUserData.phone &&
      updatingUserData.mobilePhone &&
      ownerExists &&
      updatingUserData.username &&
      updatingUserData.password
    ) {
      dispatch(
        userActions.register(
          {
            gender: updatingUserData.gender,
            firstName: updatingUserData.firstName,
            lastName: updatingUserData.lastName,
            email: updatingUserData.email,
            company: updatingUserData.company,
            street: updatingUserData.street,
            zipcode: updatingUserData.zipcode,
            city: updatingUserData.city,
            country: updatingUserData.country,
            language: updatingUserData.language,
            phone: updatingUserData.phone,
            mobilePhone: updatingUserData.mobilePhone,
            role: updatingUserData.role,
            owner: updatingUserData.owner,
            username: updatingUserData.username,
            password: updatingUserData.password
          },
          history
        )
      );
    }
  }

  ownerExists() {
    const { items, currentUser, updatingUserData } = this.props;

    if (
      items.filter(
        userInItems => userInItems.username === updatingUserData.owner
      ).length > 0 ||
      currentUser.username === updatingUserData.owner
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { registering, history, currentUser, updatingUserData } = this.props;
    const { submitted, ownerExists } = this.state;
    return (
      <div className="col-sm-12">
        <h2 className="offset-xl-3">Register</h2>
        {updatingUserData && updatingUserData.owner !== undefined ? (
          <form name="form" onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div
                className="col-sm-3 offset-xl-3"
                style={{ minWidth: "240px" }}
              >
                <MySelect
                  value={updatingUserData.gender}
                  name="gender"
                  eventHandler={this.handleChange}
                  optionsArray={["Ms.", "Mr."]}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.firstName}
                  inputName="firstName"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.lastName}
                  inputName="lastName"
                  eventHandler={this.handleChange}
                />
                <MyInputEmail
                  submitted={submitted}
                  inputValue={updatingUserData.email}
                  inputName="email"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.street}
                  inputName="street"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.zipcode}
                  inputName="zipcode"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.city}
                  inputName="city"
                  eventHandler={this.handleChange}
                />
                <MySelect
                  value={updatingUserData.country}
                  name="country"
                  eventHandler={this.handleChange}
                  optionsArray={countryList}
                />
              </div>
              <div className="col-sm-6" style={{ minWidth: "240px" }}>
                <MySelect
                  value={updatingUserData.language}
                  name="language"
                  eventHandler={this.handleChange}
                  optionsArray={["English"]}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.company}
                  inputName="company"
                  eventHandler={this.handleChange}
                />
                <MyInputTel
                  submitted={submitted}
                  inputValue={updatingUserData.phone}
                  inputName="phone"
                  eventHandler={this.handleChange}
                />
                <MyInputTel
                  submitted={submitted}
                  inputValue={updatingUserData.mobilePhone}
                  inputName="mobilePhone"
                  eventHandler={this.handleChange}
                />
                {currentUser.role === Role.Manufacturer && (
                  <>
                    <MySelect
                      value={updatingUserData.role}
                      name="role"
                      eventHandler={this.handleChange}
                      optionsArray={["User", "Partner", "Manufacturer"]}
                    />
                    <MyInputOwner
                      submitted={submitted}
                      ownerExists={ownerExists}
                      inputValue={updatingUserData.owner}
                      inputName="owner"
                      eventHandler={this.handleChange}
                    />
                  </>
                )}
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingUserData.username}
                  inputName="username"
                  eventHandler={this.handleChange}
                />
                <MyInputPassword
                  submitted={submitted}
                  inputValue={updatingUserData.password}
                  inputName="password"
                  eventHandler={this.handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-6 offset-xl-3">
                <MySubmitCancel
                  ongoingAction={registering}
                  submitButtonLabel="Register"
                  cancelExists
                  eventHandler={() => history.goBack()}
                />
              </div>
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
    );
  }
}

RegisterPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  registering: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.shape({}).isRequired,
  updatingUserData: PropTypes.shape({}).isRequired
};

RegisterPage.defaultProps = {
  registering: false,
  items: []
};

function mapStateToProps(state) {
  const { updatingUserData } = state;
  const { registering } = state.registration;
  const { items } = state.users;
  const currentUser = state.authentication.user;
  return {
    registering,
    items,
    currentUser,
    updatingUserData
  };
}

const connectedRegisterPage = withRouter(
  connect(mapStateToProps)(RegisterPage)
);
export { connectedRegisterPage as RegisterPage };
