import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { heatpumpActions } from "../_actions";
import { heatpumpConstants, config } from "../_constants";

class MainScreenPage extends React.Component {
  // sessionDuration in Node 30 seconds
  static refreshingSessionInterval = 1000 * (60 * 2 + 20); // 2:20 min

  static hideScreenTime = 1000 * 60; // 60 seconds

  constructor(props) {
    super(props);
    const { user, dispatch } = this.props;
    if (user.activeHeatpump) {
      dispatch({ type: heatpumpConstants.HEATPUMP_CONNECTION_REQUEST });
      dispatch(heatpumpActions.getByIdAndCheckConnection(user.activeHeatpump));
    }

    this.touchViewportWidth = 480
    this.touchViewportHeight = 272

    // console.log("konstruishem komponentu");
    this.state = {
      // Check if hp is online on page load
      connectionIntervalId: setInterval(
        () => this.activeHeatpumpOnlineCheck(user, dispatch),
        MainScreenPage.refreshingSessionInterval
      ),
      hideIfremeTimeoutId: 0,
      screenActive: true,
      screenActiveFirstTime: true,
      screenIsTurningOff: false,
      iframePosition: "fixed",
      iframeWidth: "480px",
      iframeHeight: "272px",
      zoomScale: 1.2,
      distanceFromTop: 0,
      distanceFromLeft: 0,
    };
  }

  componentDidMount() {
    // console.log("mountujem komponentu");
    // window.addEventListener("focus", this.onFocus);
    // window.addEventListener("blur", this.onBlur);

    window.addEventListener("resize", this.windowResizeHandler);
    this.windowResizeHandler();
    document.addEventListener("visibilitychange", this.visibilityChangeHandler);
    if (document.hidden) {
      this.visibilityChangeHandler();
    }
  }

  componentWillUnmount() {
    const { connectionIntervalId } = this.state;
    // console.log("unmountujem komponentu");
    // window.removeEventListener("focus", this.onFocus);
    // window.removeEventListener("blur", this.onBlur);
    window.removeEventListener("resize", this.windowResizeHandler);
    document.removeEventListener(
      "visibilitychange",
      this.visibilityChangeHandler
    );
    clearInterval(connectionIntervalId);
  }

  visibilityChangeHandler = () => {
    const {
      hideIfremeTimeoutId,
      screenActive,
      screenIsTurningOff
    } = this.state;
    // const { user, dispatch } = this.props;

    if (document.hidden) {
      if (screenActive && !screenIsTurningOff) {
        this.setState({
          hideIfremeTimeoutId: setTimeout(
            () => this.hideScreenHandler(),
            MainScreenPage.hideScreenTime
          ),
          screenIsTurningOff: true
        });
      }
      // console.log("izvan fokusa sam");
    } else {
      // eslint-disable-next-line no-lonely-if
      if (screenIsTurningOff) {
        clearTimeout(hideIfremeTimeoutId);
        this.setState({ screenIsTurningOff: false, screenActive: true });
      } else if (!screenActive) {
        this.setState({ screenActive: true });
      }
      // console.log("u fokusu sam");
    }
  };

  /* onFocus = () => {
    console.log(document.visibilityState);
    const {
      hideIfremeTimeoutId,
      screenActive,
      screenIsTurningOff
    } = this.state;
    const { user, dispatch } = this.props;

    // if timer is about to shut the screen stop the timer
    if (screenIsTurningOff) {
      clearTimeout(hideIfremeTimeoutId);
      this.setState({ screenIsTurningOff: false, screenActive: true });
    } else if (!screenActive) {
      this.setState({ screenActive: true });
    }
    console.log("u fokusu sam");
  };

  onBlur = () => {
    console.log(document.visibilityState);
    // if blure is not caused by clicking on screen
    if (document.activeElement !== document.getElementById("main-screen")) {
      console.log("izvan fokusa sam");
      const { screenActive, screenIsTurningOff } = this.state;

      // if screen is active or is not going to be shut, start the timer for turning off the screen
      if (screenActive || !screenIsTurningOff) {
        this.setState({
          hideIfremeTimeoutId: setTimeout(
            () => this.hideScreenHandler(),
            MainScreenPage.hideScreenTime
          ),
          screenIsTurningOff: true
        });
      }
    }
  }; */

  componentDidUpdate = (prevProps) => {
    const { activeHeatpumpData: { model } } = this.props
    if (model !== prevProps.activeHeatpumpData.model) {
      if (model.endsWith('-7')) {
        this.touchViewportWidth = 800
        this.touchViewportHeight = 480
      } else {
        this.touchViewportWidth = 480
        this.touchViewportHeight = 272
      }
      this.windowResizeHandler()
    }
  }

  windowResizeHandler = () => {
    function getWidth() {
      let xWidth = null;
      // if (window.screen != null) xWidth = window.screen.availWidth;

      if (window.innerWidth != null) xWidth = window.innerWidth;

      // if (document.body != null) xWidth = document.body.clientWidth;

      return xWidth;
    }

    function getHeight() {
      let xHeight = null;
      // if (window.screen != null) xHeight = window.screen.availHeight;

      if (window.innerHeight != null) xHeight = window.innerHeight;

      // if (document.body != null) xHeight = document.body.clientHeight;

      return xHeight;
    }

    if (getWidth() < 900) {
      let zoomScale = 1.2;
      let distanceFromTop = 0;
      let distanceFromLeft = 0;
      const mobileLendscapeMenuWidth = getWidth() / getHeight() > 1 ? 58 : 0;

      if (
        getWidth() &&
        (getWidth() - mobileLendscapeMenuWidth) / this.touchViewportWidth < getHeight() / this.touchViewportHeight
      ) {
        zoomScale = (getWidth() - mobileLendscapeMenuWidth) / this.touchViewportWidth;
        distanceFromTop = (getHeight() - this.touchViewportHeight * zoomScale) / 2;
        distanceFromLeft =
          (getWidth() + mobileLendscapeMenuWidth - this.touchViewportWidth * zoomScale) / 2;
      } else if (getHeight()) {
        zoomScale = getHeight() / this.touchViewportHeight;
        distanceFromTop = (getHeight() - this.touchViewportHeight * zoomScale) / 2;
        distanceFromLeft =
          (getWidth() + mobileLendscapeMenuWidth - this.touchViewportWidth * zoomScale) / 2;
      }

      this.setState({
        iframePosition: "fixed",
        iframeWidth: `${this.touchViewportWidth}px`,
        iframeHeight: `${this.touchViewportHeight}px`,
        zoomScale,
        distanceFromTop,
        distanceFromLeft
      });
    } else {
      this.setState({
        iframePosition: "relative",
        iframeWidth: "inherit",
        /* iframeWidth: "100%",
        iframeWidth: "fill-available",
        iframeWidth: "-webkit-fill-available", // Mozilla-based browsers will ignore this.
        iframeWidth: "-moz-available", // WebKit-based browsers will ignore this. */
        iframeHeight: getHeight() - 180,
        zoomScale: 1,
        distanceFromTop: 0,
        distanceFromLeft: 0
      });
    }

    // console.log(getWidth(), ":", getHeight());
  };

  hideScreenHandler() {
    // console.log("screen hided");
    this.setState({ screenActive: false, screenIsTurningOff: false });
  }

  activeHeatpumpOnlineCheck(user, dispatch) {
    // provera da li je pumpa aktivna
    this.setState({ screenActiveFirstTime: false });

    if (user.activeHeatpump) {
      dispatch(heatpumpActions.getByIdAndCheckConnection(user.activeHeatpump));
    }
  }

  render() {
    const { activeHeatpumpStatus, checkingConnection, activeHeatpumpData } = this.props;
    const {
      screenActive,
      screenActiveFirstTime,
      iframePosition,
      iframeWidth,
      iframeHeight,
      zoomScale,
      distanceFromTop,
      distanceFromLeft
    } = this.state;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className='main-screen-hp-model h1'>{activeHeatpumpData.name}</div>
          {/* <h1>Hi {user.firstName}!</h1>
          <p>Welcome to MainScreenPage!!</p> */}
          {activeHeatpumpStatus &&
            screenActive &&
            (!screenActiveFirstTime || !checkingConnection) ? (
              <>
                <iframe
                  id="main-screen"
                  title="GTR heatpump"
                  src={`${config.apiUrl}/index.htm`}
                  frameBorder="0"
                  scrolling="no"
                  style={{
                    position: iframePosition,
                    top: distanceFromTop,
                    left: distanceFromLeft,
                    width: iframeWidth,
                    height: iframeHeight,
                    msZoom: zoomScale,
                    MozTransform: `scale(${zoomScale})`,
                    MozTransformOrigin: "0 0",
                    OTransform: `scale(${zoomScale})`,
                    OTransformOrigin: "0 0",
                    WebkitTransform: `scale(${zoomScale})`,
                    WebkitTransformOrigin: "0 0"
                  }}
                />
                {/* <object
                  id="main-screen"
                  aria-label="GTR heatpump"
                  data="http://192.168.0.17:4000/index.htm"
                />
               */}
                {/* <embed
                id="main-screen"
                src="http://192.168.0.17:4000/index.htm"
              /> */}
              </>
            ) : (
              <p>Heat pump screen is disconnected!</p>
            )}
        </div>
      </div>
    );
  }
}

MainScreenPage.propTypes = {
  user: PropTypes.shape({}).isRequired,
  activeHeatpumpStatus: PropTypes.bool,
  checkingConnection: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeHeatpumpData: PropTypes.shape({ model: PropTypes.string }),
};

MainScreenPage.defaultProps = {
  activeHeatpumpStatus: false,
  checkingConnection: false,
  activeHeatpumpData: { model: '' },
};

function mapStateToProps(state) {
  const {
    authentication: { user },
    heatpumps: { activeHeatpumpStatus, checkingConnection, activeHeatpumpData }
  } = state;
  return {
    user,
    activeHeatpumpStatus,
    checkingConnection,
    activeHeatpumpData,
  };
}

const connectedMainScreenPage = connect(mapStateToProps)(MainScreenPage);
export { connectedMainScreenPage as MainScreenPage };
