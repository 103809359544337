import { userConstants } from "../_constants";

// const initialState = JSON.parse(localStorage.getItem("updatingUser")) || {};

export function updatingUserData(state = {}, action) {
  switch (action.type) {
    case userConstants.UPDATING_USER_DATA:
      /* updatingUserData = JSON.parse(localStorage.getItem('updatingUser'));
            const currentState = updatingUserData ? updatingUserData : {};        
            return currentState; */
      // console.log("updastingUserData reducer:", action.user);
      return action.user ? action.user : {};
    case userConstants.CLEAR_UPDATING_USER_DATA:
      return {};
    default:
      return state;
  }
}
