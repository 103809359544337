import React from "react";
import PropTypes from "prop-types";

const propTypes = { loggedOut: PropTypes.bool };

export function Footer({ loggedOut }) {
  return (
    <div>
      {loggedOut ? (
        ""
      ) : (
        <div className="text-center">GEOTHERMAL HEAT PUMPS</div>
      )}
    </div>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = {
  loggedOut: false
};
