import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { userActions, changeUpdatingUserData } from "../_actions";
import { Role } from "../_helpers";
import { countryList } from "../_constants";
import { Page404 } from "../_components";
import {
  MyInputText,
  MySelect,
  MyInputEmail,
  MyInputOwner,
  MyInputTel,
  MySubmitCancel,
  MyBootstrapModal
} from "../_forms";

class UpdatePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      ownerExists: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      updatingUserData,
      dispatch,
      location,
      match,
      currentUser,
      users
    } = this.props;
    if (!updatingUserData.id || match.params.id !== updatingUserData.id) {
      dispatch(
        userActions.getUpdateDataAfterRefresh(dispatch, location.pathname)
      );
    }
    if (currentUser.role === Role.Manufacturer && users.length === 0) {
      dispatch(userActions.getAll());
    }
  }

  handleDeleteUser() {
    const { dispatch, history, updatingUserData } = this.props;
    dispatch(
      userActions.delete(
        updatingUserData.id,
        history,
        updatingUserData.prevPath
      )
    );
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { dispatch, updatingUserData } = this.props;

    this.setState({
      ownerExists: true
    });

    dispatch(
      changeUpdatingUserData({
        ...updatingUserData,
        [name]: value
      })
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    const { dispatch, history, updatingUserData } = this.props;
    const ownerExists = this.ownerExists();

    this.setState({ submitted: true, ownerExists });

    if (
      updatingUserData.gender &&
      updatingUserData.firstName &&
      updatingUserData.lastName &&
      updatingUserData.email &&
      updatingUserData.street &&
      updatingUserData.zipcode &&
      updatingUserData.city &&
      updatingUserData.country &&
      updatingUserData.language &&
      updatingUserData.phone &&
      updatingUserData.mobilePhone &&
      ownerExists
    ) {
      dispatch(
        userActions.update(updatingUserData, history, updatingUserData.prevPath)
      );
    }
  }

  ownerExists() {
    const { users, currentUser, updatingUserData } = this.props;

    if (
      users.filter(
        userInItems => userInItems.username === updatingUserData.owner
      ).length > 0 ||
      currentUser.username === updatingUserData.owner
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      updating,
      history,
      currentUser,
      updatingUserData,
      match,
      users
    } = this.props;
    const { submitted, ownerExists } = this.state;
    const modalId = "confirmModal";

    if (
      users &&
      updatingUserData &&
      updatingUserData.id &&
      match.params.id === updatingUserData.id
    ) {
      const myUser =
        users.filter(heatpump => heatpump._id === updatingUserData.id).length >
        0;

      if (myUser) {
        return (
          <div className="col-sm-12">
            <h2 className="offset-xl-3">
              Update user {updatingUserData.username}:
            </h2>

            <form name="form" onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div
                  className="col-sm-3 offset-xl-3"
                  style={{ minWidth: "240px" }}
                >
                  <MySelect
                    value={updatingUserData.gender}
                    name="gender"
                    eventHandler={this.handleChange}
                    optionsArray={["Ms.", "Mr."]}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingUserData.firstName}
                    inputName="firstName"
                    eventHandler={this.handleChange}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingUserData.lastName}
                    inputName="lastName"
                    eventHandler={this.handleChange}
                  />
                  <MyInputEmail
                    submitted={submitted}
                    inputValue={updatingUserData.email}
                    inputName="email"
                    eventHandler={this.handleChange}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingUserData.street}
                    inputName="street"
                    eventHandler={this.handleChange}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingUserData.zipcode}
                    inputName="zipcode"
                    eventHandler={this.handleChange}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingUserData.city}
                    inputName="city"
                    eventHandler={this.handleChange}
                  />
                </div>
                <div className="col-sm-3" style={{ minWidth: "240px" }}>
                  <MySelect
                    value={updatingUserData.country}
                    name="country"
                    eventHandler={this.handleChange}
                    optionsArray={countryList}
                  />
                  <MySelect
                    value={updatingUserData.language}
                    name="language"
                    eventHandler={this.handleChange}
                    optionsArray={["English"]}
                  />
                  <MyInputText
                    submitted={submitted}
                    inputValue={updatingUserData.company}
                    inputName="company"
                    eventHandler={this.handleChange}
                  />
                  <MyInputTel
                    submitted={submitted}
                    inputValue={updatingUserData.phone}
                    inputName="phone"
                    eventHandler={this.handleChange}
                  />
                  <MyInputTel
                    submitted={submitted}
                    inputValue={updatingUserData.mobilePhone}
                    inputName="mobilePhone"
                    eventHandler={this.handleChange}
                  />
                  {currentUser.role === Role.Manufacturer && (
                    <>
                      <MySelect
                        value={updatingUserData.role}
                        name="role"
                        eventHandler={this.handleChange}
                        optionsArray={["User", "Partner", "Manufacturer"]}
                      />
                      <MyInputOwner
                        submitted={submitted}
                        ownerExists={ownerExists}
                        inputValue={updatingUserData.owner}
                        inputName="owner"
                        eventHandler={this.handleChange}
                      />
                    </>
                  )}
                </div>
                <div className="col-lg-3 pt-4 pb-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target={`#${modalId}`}
                  >
                    Delete User
                  </button>
                  <MyBootstrapModal
                    modalId={modalId}
                    item="user"
                    eventHandler={() => this.handleDeleteUser()}
                  />
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.handleDeleteUser()}
                  >
                    Delete User
                  </button> */}
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-3 offset-xl-3">
                  <MySubmitCancel
                    ongoingAction={updating}
                    submitButtonLabel="Update"
                    cancelExists
                    eventHandler={() => history.goBack()}
                  />
                </div>
              </div>
            </form>
          </div>
        );
      }
      return <Page404 />;
    }
    return "";
  }
}

UpdatePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  updating: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object),
  updatingUserData: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired
};

UpdatePage.defaultProps = {
  updating: false,
  users: []
};

function mapStateToProps(state) {
  const { updating } = state.updating;
  const { updatingUserData, authentication } = state;
  const users = state.users.items;
  const currentUser = authentication.user;
  return {
    updating,
    updatingUserData,
    users,
    currentUser
  };
}

const connectedUpdatePage = withRouter(connect(mapStateToProps)(UpdatePage));
export { connectedUpdatePage as UpdatePage };
