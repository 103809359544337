import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { MyInputText, MyInputPassword, MySubmitCancel } from "../_forms";
import { userActions } from "../_actions";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    const { dispatch } = this.props;
    dispatch(userActions.logout());

    this.state = {
      username: "",
      password: "",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch, history } = this.props;
    if (username && password) {
      dispatch(userActions.login(username, password, history));
    }
  }

  render() {
    const { loggingIn } = this.props;
    const { username, password, submitted } = this.state;
    return (
      <div className="row justify-content-lg-center align-items-center">
        <div className="col col-lg-6 text-right">
          <img
            id="login-logo"
            className="img"
            width="206px"
            alt=""
            src="img/gtr-logo.svg"
          />
        </div>
        <div className="col col-lg-6">
          <h2>Login</h2>

          <form name="form" onSubmit={this.handleSubmit}>
            <MyInputText
              submitted={submitted}
              inputValue={username}
              inputName="username"
              eventHandler={this.handleChange}
            />
            <MyInputPassword
              submitted={submitted}
              inputValue={password}
              inputName="password"
              eventHandler={this.handleChange}
            />
            <MySubmitCancel
              ongoingAction={loggingIn}
              submitButtonLabel="Login"
              cancelExists={false}
            />
          </form>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  loggingIn: PropTypes.bool
};

LoginPage.defaultProps = {
  loggingIn: false
};

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
}

const connectedLoginPage = withRouter(connect(mapStateToProps)(LoginPage));
export { connectedLoginPage as LoginPage };
