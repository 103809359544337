import React from "react";
import PropTypes from "prop-types";
import { validate } from "../_helpers";

const propTypes = {
  submitted: PropTypes.bool.isRequired,
  inputValue: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  eventHandler: PropTypes.func.isRequired
};

export function MyInputIp({ submitted, inputValue, inputName, eventHandler }) {
  const inputNameForUser = inputName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, str => str.toUpperCase());

  return (
    <div
      className={`form-group${
        submitted && !validate.ipAddres(inputValue) ? " text-danger" : ""
      }`}
    >
      <label htmlFor={inputName}>
        {inputNameForUser}
        <input
          type="text"
          className="form-control"
          name={inputName}
          value={inputValue}
          onChange={eventHandler}
          placeholder={`Enter your ${inputNameForUser}`}
        />
      </label>
      {submitted && !validate.ipAddres(inputValue) && (
        <div className="help-block">{inputNameForUser} is not good</div>
      )}
    </div>
  );
}

MyInputIp.propTypes = propTypes;
MyInputIp.defaultProps = {
  inputValue: ""
};
