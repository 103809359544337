import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { userActions, changeUpdatingUserData } from "../_actions";
import { MyInputPassword, MySubmitCancel } from "../_forms";

class ChangePassPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { updatingUserData, dispatch, currentUser } = this.props;
    if (
      !updatingUserData.id ||
      currentUser._id !== updatingUserData.id ||
      !updatingUserData.password
    ) {
      dispatch(
        changeUpdatingUserData({
          id: currentUser._id,
          gender: currentUser.gender,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
          company: currentUser.company,
          street: currentUser.street,
          zipcode: currentUser.zipcode,
          city: currentUser.city,
          country: currentUser.country,
          language: currentUser.language,
          phone: currentUser.phone,
          mobilePhone: currentUser.mobilePhone,
          username: currentUser.username,
          password: "",
          newPassword: "",
          prevPath: "/"
        })
      );
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { dispatch, updatingUserData } = this.props;

    dispatch(
      changeUpdatingUserData({
        ...updatingUserData,
        [name]: value
      })
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    const { updatingUserData, dispatch, history } = this.props;

    this.setState({ submitted: true });

    if (updatingUserData.password && updatingUserData.newPassword) {
      dispatch(
        userActions.changePassword(
          updatingUserData.username,
          updatingUserData.password,
          updatingUserData.newPassword,
          history,
          updatingUserData.prevPath
        )
      );
    }
  }

  render() {
    const { changingPass, history, updatingUserData, currentUser } = this.props;
    const { submitted } = this.state;
    return (
      <div className="col-sm-9 offset-lg-3" style={{ minWidth: "240px" }}>
        <h2>Update your password {updatingUserData.username}:</h2>
        {updatingUserData &&
        updatingUserData.id &&
        currentUser._id === updatingUserData.id ? (
          <form name="form" onSubmit={this.handleSubmit}>
            <MyInputPassword
              submitted={submitted}
              inputValue={updatingUserData.password}
              inputName="password"
              eventHandler={this.handleChange}
            />
            <MyInputPassword
              submitted={submitted}
              inputValue={updatingUserData.newPassword}
              inputName="newPassword"
              eventHandler={this.handleChange}
            />
            <MySubmitCancel
              ongoingAction={changingPass}
              submitButtonLabel="Update"
              cancelExists
              eventHandler={() => history.goBack()}
            />
          </form>
        ) : (
          ""
        )}
      </div>
    );
  }
}

ChangePassPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  changingPass: PropTypes.bool,
  updatingUserData: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired
};

ChangePassPage.defaultProps = {
  changingPass: false
};

function mapStateToProps(state) {
  const { changingPass } = state.changePass;
  const { updatingUserData, authentication } = state;
  const currentUser = authentication.user;
  return {
    changingPass,
    updatingUserData,
    currentUser
  };
}

const connectedRegisterPage = withRouter(
  connect(mapStateToProps)(ChangePassPage)
);
export { connectedRegisterPage as ChangePassPage };
