import { Role } from "../_helpers";
import { heatpumpActions } from "../_actions";

export function updateHeatpumpsList(dispatch, currentUser) {
  const checkConnection = true;

  if (currentUser.role === Role.Manufacturer) {
    dispatch(heatpumpActions.getAll(checkConnection));
  } else if (currentUser.role === Role.Partner) {
    dispatch(
      heatpumpActions.getAllPartnersUsers(currentUser.username, checkConnection)
    );
  } else if (currentUser.role === Role.User) {
    dispatch(heatpumpActions.getByOwner(currentUser.username, checkConnection));
  }
}
