import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { heatpumpActions, changeUpdatingHeatpumpData } from "../_actions";
import { Role, validate } from "../_helpers";
import { countryList } from "../_constants";
import {
  MyInputText,
  MySelect,
  MyInputOwner,
  MySubmitCancel,
  MyInputIp
} from "../_forms";

class RegisterHeatpumpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      ownerExists: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { updatingHeatpumpData, dispatch, currentUser } = this.props;
    if (!updatingHeatpumpData.owner) {
      dispatch(
        changeUpdatingHeatpumpData({
          serial: "",
          name: "",
          street: "",
          zipcode: "",
          city: "",
          country: "Serbia",
          model: "",
          ipAddress: "",
          owner: currentUser.username,
          registeringKey: ""
        })
      );
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { dispatch, updatingHeatpumpData } = this.props;

    dispatch(
      changeUpdatingHeatpumpData({
        ...updatingHeatpumpData,
        [name]: value
      })
    );

    this.setState({
      ownerExists: true
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { dispatch, history, updatingHeatpumpData } = this.props;
    const ownerExists = this.ownerExists();

    this.setState({ submitted: true, ownerExists });

    if (
      updatingHeatpumpData.serial &&
      updatingHeatpumpData.name &&
      updatingHeatpumpData.street &&
      updatingHeatpumpData.zipcode &&
      updatingHeatpumpData.city &&
      updatingHeatpumpData.country &&
      updatingHeatpumpData.model &&
      validate.ipAddres(updatingHeatpumpData.ipAddress) &&
      ownerExists
    ) {
      dispatch(
        heatpumpActions.register(
          {
            serial: updatingHeatpumpData.serial,
            name: updatingHeatpumpData.name,
            street: updatingHeatpumpData.street,
            zipcode: updatingHeatpumpData.zipcode,
            city: updatingHeatpumpData.city,
            country: updatingHeatpumpData.country,
            model: updatingHeatpumpData.model,
            ipAddress: updatingHeatpumpData.ipAddress,
            owner: updatingHeatpumpData.owner
          },
          history
        )
      );
    }
  }

  ownerExists() {
    const { items, currentUser, updatingHeatpumpData } = this.props;

    if (
      items.filter(
        userInItems => userInItems.username === updatingHeatpumpData.owner
      ).length > 0 ||
      currentUser.username === updatingHeatpumpData.owner
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      registering,
      history,
      currentUser,
      updatingHeatpumpData
    } = this.props;
    const { submitted, ownerExists } = this.state;
    return (
      <div className="col-sm-12">
        <h2 className="offset-xl-3">Register</h2>
        {updatingHeatpumpData && updatingHeatpumpData.owner !== undefined ? (
          <form name="form" onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div
                className="col-sm-3 offset-xl-3"
                style={{ minWidth: "240px" }}
              >
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingHeatpumpData.serial}
                  inputName="serial"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingHeatpumpData.name}
                  inputName="name"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingHeatpumpData.model}
                  inputName="model"
                  eventHandler={this.handleChange}
                />
                <MyInputIp
                  submitted={submitted}
                  inputValue={updatingHeatpumpData.ipAddress}
                  inputName="ipAddress"
                  eventHandler={this.handleChange}
                />
                {currentUser.role === Role.Manufacturer && (
                  <MyInputOwner
                    submitted={submitted}
                    ownerExists={ownerExists}
                    inputValue={updatingHeatpumpData.owner}
                    inputName="owner"
                    eventHandler={this.handleChange}
                  />
                )}
              </div>
              <div className="col-sm-6" style={{ minWidth: "240px" }}>
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingHeatpumpData.street}
                  inputName="street"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingHeatpumpData.zipcode}
                  inputName="zipcode"
                  eventHandler={this.handleChange}
                />
                <MyInputText
                  submitted={submitted}
                  inputValue={updatingHeatpumpData.city}
                  inputName="city"
                  eventHandler={this.handleChange}
                />
                <MySelect
                  value={updatingHeatpumpData.country}
                  name="country"
                  eventHandler={this.handleChange}
                  optionsArray={countryList}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-6 offset-xl-3">
                <MySubmitCancel
                  ongoingAction={registering}
                  submitButtonLabel="Register"
                  cancelExists
                  eventHandler={() => history.goBack()}
                />
              </div>
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
    );
  }
}

RegisterHeatpumpPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  registering: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.shape({}).isRequired,
  updatingHeatpumpData: PropTypes.shape({}).isRequired
};

RegisterHeatpumpPage.defaultProps = {
  registering: false,
  items: []
};

function mapStateToProps(state) {
  const { updatingHeatpumpData } = state;
  const { registering } = state.registration;
  const { items } = state.users;
  const currentUser = state.authentication.user;
  return {
    registering,
    items,
    currentUser,
    updatingHeatpumpData
  };
}

const connectedRegisterHeatpumpPage = withRouter(
  connect(mapStateToProps)(RegisterHeatpumpPage)
);
export { connectedRegisterHeatpumpPage as RegisterHeatpumpPage };
