import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  ongoingAction: PropTypes.bool.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  cancelExists: PropTypes.bool.isRequired,
  eventHandler: PropTypes.func
};

export function MySubmitCancel({
  ongoingAction,
  submitButtonLabel,
  cancelExists,
  eventHandler
}) {
  return (
    <div className="form-group">
      <button
        type="submit"
        className="btn btn-primary"
        id="register-update-btn"
      >
        {submitButtonLabel}
      </button>
      {ongoingAction && (
        <span>
          &nbsp;&nbsp;
          <img
            alt=""
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
        </span>
      )}
      {cancelExists && (
        <button type="button" className="btn btn-link" onClick={eventHandler}>
          Cancel
        </button>
      )}
    </div>
  );
}

MySubmitCancel.propTypes = propTypes;
MySubmitCancel.defaultProps = {
  eventHandler: PropTypes.func
};
