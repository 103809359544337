// Form validations file

function ipAddres(inputValue) {
  if (inputValue) {
    const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (inputValue.match(ipformat)) {
      return true;
    }
    return false;
  }
  return true;
}

export const validate = {
  ipAddres
};
