import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function HomePage(props) {
  const { currentUser } = props;

  return (
    <div className="row">
      <div className="col col-md-6 offset-lg-2">
        <h1>Hi {currentUser.firstName}!</h1>
        <p>Welcome to GTR Heatpumps!!</p>
      </div>
    </div>
  );
}

HomePage.propTypes = {
  currentUser: PropTypes.shape({}).isRequired
};

function mapStateToProps(state) {
  const { authentication } = state;
  const currentUser = authentication.user;
  return {
    currentUser
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
